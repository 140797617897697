<template>
  <v-card>
    <v-card-title>Edit Articles</v-card-title>
    <v-card-text>
      <v-form
        ref="formArticle"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="articleData.name"
          label="Name"
          name="name"
          outlined
          placeholder="Name"
          :rules="[required]"
          :error-messages="errorMessages.name"
          @focus="errorMessages.name = null"
        ></v-text-field>

        <v-row align="center">
          <v-col
            class="d-flex"
            cols="6"
            sm="6"
          >
            <v-select
              v-model="section_id"
              :items="sections"
              label="Section"
              name="section_id"
              item-text="name"
              item-value="id"
              outlined
              placeholder="Section"

              :error-messages="errorMessages.section_id"
              @focus="errorMessages.section_id = null"
            ></v-select>
          </v-col>
          <v-col
            class="d-flex"
            cols="6"
            sm="6"
          >
            <v-select
              v-model="articleData.category_id"
              :items="categories"
              label="Category"
              name="category_id"
              item-text="name"
              item-value="id"
              outlined
              placeholder="Category"

              :error-messages="errorMessages.category_id"
              @focus="errorMessages.category_id = null"
            ></v-select>
          </v-col>
        </v-row>

        <v-select
          v-model="articleData.author_id"
          :items="authors"
          label="Author"
          name="author_id"
          item-text="name"
          item-value="id"
          outlined
          placeholder="Author"

          :error-messages="errorMessages.author_id"
          @focus="errorMessages.author_id = null"
        ></v-select>

        <v-text-field
          v-model="articleData.slug"
          label="Slug"
          name="slug"
          outlined
          placeholder="Slug"
          :rules="[required]"
          :error-messages="errorMessages.slug"
           @focus="errorMessages.slug = null"
        ></v-text-field>

        <form-image
          :image="articleData.teaser_image"
          :error-messages="errorMessages"
          @clear="articleData.teaser_image = null"
          @update="articleData.teaser_image = $event"
        />

        <v-textarea
          v-model="articleData.intro"
          label="Intro"
          name="intro"
          outlined
          counter="400"
          auto-grow
          placeholder="Intro"
          :rules="[required]"
          :error-messages="errorMessages.intro"
          @focus="errorMessages.intro = null"
        ></v-textarea>

        <tinymce id="article-content"
                 :other_options="tinyOptions"
                 v-model="articleData.body"></tinymce>

        <v-checkbox
          v-model="articleData.is_published"
          label="Published"
          class="mt-0"
          :error-messages="errorMessages.is_published"
          @focus="errorMessages.is_published = null"
        ></v-checkbox>

        <seo-meta :error-messages="errorMessages" :seo-data="seoData"/>

        <v-btn color="primary" type="submit">
          Save
        </v-btn>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {onUnmounted, ref, toRef} from '@vue/composition-api'
import store from '@/store'
import articlesStore from './store'
import categoriesStore from '../Categories/store'
import sectionsStore from '../Sections/store'
import useArticles from "@/views/Content/Articles/composition";
import SeoMeta from '@/components/SeoMeta.vue'
import FormImage from '@/components/Form/UploadImage.vue'
import FormEditor from '@/components/Form/Editor.vue'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'

export default {
  components: {
    SeoMeta,
    FormImage,
    FormEditor,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-content-articles'
    const CATEGORY_MODULE_NAME = 'app-content-categories'
    const SECTION_MODULE_NAME = 'app-content-sections'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, articlesStore)
    }

    if (!store.hasModule(CATEGORY_MODULE_NAME)) {
      store.registerModule(CATEGORY_MODULE_NAME, categoriesStore)
    }

    if (!store.hasModule(SECTION_MODULE_NAME)) {
      store.registerModule(SECTION_MODULE_NAME, sectionsStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      if (store.hasModule(CATEGORY_MODULE_NAME)) store.unregisterModule(CATEGORY_MODULE_NAME)
      if (store.hasModule(SECTION_MODULE_NAME)) store.unregisterModule(SECTION_MODULE_NAME)
    })

    const { route } = useRouter()

    const formArticle = ref(null)

    const handleFormSubmit = () => {
      if (!formArticle.value.validate()) {
        toast.warning('Validation errors')
        return
      }
      updateArticle(route.value.params.id, articleData.value)
    }

    const {
      loading,
      articleData,
      seoData,
      errorMessages,
      toast,
      categories,
      sections,
      authors,
      updateArticle,
      fetchArticle,
      getCategories,
      getAuthors,
      section_id,
      getSections,
      bodyHtml,
      tinyOptions,
    } = useArticles()

    getCategories()
    getSections()
    getAuthors()
    fetchArticle(route.value.params.id)

    return {
      loading,
      articleData,
      seoData,
      errorMessages,
      formArticle,
      toast,
      categories,
      sections,
      authors,
      handleFormSubmit,
      updateArticle,
      required,
      bodyHtml,
      tinyOptions,
      section_id,
      getSections,
    }
  },
}
</script>
